import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["select", "source"]
  static values = {
    url: String
  }

  connect() {    
    this.change()
  }

  change(event) {
    get(this.urlValue, {
      responseKind: "turbo-stream",
      query: {
        target: this.selectTarget.id,
        value: this.sourceTarget.selectedOptions[0].value
      }
    })
  }
}
